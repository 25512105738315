import * as React from 'react';

import PlansAndPricingPage from '../ui/pages/PlansAndPricingPage';

// markup
const Page = () => {
  return <PlansAndPricingPage />;
};

export default Page;
