import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DefaultLayout from "../../layouts/DefaultLayout";
import Seo from "../../../app/Seo";
import Page from "../../components/Page";

import pricingTranslations from "../../../locales-js/en/pricing";

export default function PlansAndPricingPage() {
  return (
    <>
      <Seo title="Plans and Pricing" />
      <DefaultLayout>
        <Page pageTitle="Plans and pricing">
          <Box sx={{ margin: "auto", maxWidth: "600px" }}>
            <Typography variant="h5" align="justify" gutterBottom={true}>
              {pricingTranslations.paragraph1}
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom={true}>
              {pricingTranslations.paragraph2}
            </Typography>
            {/*<Typography variant="h5" align="justify" gutterBottom={true}>*/}
            {/*  {pricingTranslations.paragraph3}*/}
            {/*</Typography>*/}
          </Box>
        </Page>
      </DefaultLayout>
    </>
  );
}
