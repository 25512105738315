const pricingTranslations = {
  paragraph1:
    "Magic Audio is the outcome of continuous scientific research and active development.",
  paragraph2:
    "Currently, we keep on improving the quality & functionality of our products and they are available for free without any limitations.",
  paragraph3:
    "In Q4 2022, Magic Audio will be a subscription-based service at the affordable price of about $1-$2 per month. The free plan will still be supported, with usage/time-based limitations.",
};

export default pricingTranslations;
